import React, { useState, useEffect } from 'react';
import hello from './img/images.jpg';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CommanInput from './Constent/CommanInput';
import CommanDropDown from './Constent/CommanDropDown';
import { GET_WITH_TOKEN, GET_WITH_TOKEN_PARAMS, Post_WITH_TOKEN } from '../Backend/Bakend';
import { showToast } from './Constent/Toast';
import { Button } from 'react-bootstrap';
import { convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import Imges from './Constent/Imges';
import BasicPagination from './Pagination';


const Subcat = () => {


  useEffect(() => {
    GetbaseCat(1);
    GetCat();
  }, [])
  const [questions, setQuestions] = useState([])
  const [BaseList, setBaseList] = useState([])
  const [Edit, setEdit] = useState(false)
  const [BaseCatImgUrl, setBaseCatImgUrl] = useState('');
  const [BaseCatImg, setBaseCatImg] = useState('');
  const [id, setId] = useState('');
  const [TotalPage, setTotalPage] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1);
  const [search, setsearch] = useState('');

  const AddNewQuestions = ()=>{
    const Array = [...questions];
    Array.push({name:"",value:""});
    setQuestions(Array);
   }

  const handleSubmit = () => {
    const validationErrors = validateForm(Data);
    if (Edit) {
      CreatePost();
    }
    else {
      if (Object.keys(validationErrors).length === 0) {
        CreatePost();
      } else {
        showToast("All fields are required");
      }
    }
  };

  const validateForm = (formData) => {
    const errors = {};
    Object.keys(formData).forEach((fieldName) => {
      if (fieldName === "base_image") {
        if (!formData[fieldName] || formData[fieldName].length === 0) {
          errors[fieldName] = "Base image is required";
        }
      }
    });
    return errors;
  };

  const DeleteQuestion = (index)=>{
    const Array = [...questions];

   console.log(Array.splice(index,1),"hello");

   setQuestions(Array)

  }
  


  const [Data, setData] = useState({
    name: "",
    slug: "",
    upr_sec_title: "",
    upr_sec_desc: "",
    most_popular_category_id: "",
    middle_section_category_id: '',
    lower_section_category_id: '',
    additional_lower_section_category_id: "",
    low_sec_title: "",
    low_sec_desc: "",
    base_image: [],
    cta_description: "",
    cta_title: "",
    publish: 1,
    status: 1,
  })

  console.log(Data, "data")


  //  const Array=[{id:1,name:"My First",value:1},{id:1,name:"My First",value:1},{id:1,name:"My First",value:1}]

  const [categoriesData, setcategoriesData] = useState([])


  // const GetbaseCat = () => {
  //   GET_WITH_TOKEN("/api/allbasecategories", "", e => {
  //     setBaseList(e.data);
  //     console.log(e, "eeeeee")
  //     // showToast("success")
  //     // setcategoriesData(e.data)
  //   }, f => {
  //     showToast(f.message || f.msg)
  //   }, n => {
  //     showToast(n.message || n.msg || "Somthing Wants to rong")
  //   })
  // }

  const GetbaseCat = async(page)=>{
    GET_WITH_TOKEN(`/api/allbasecategories?search=${search}&page=${page}`,"",
    onSuccess => {
      console.log('Success:', onSuccess);
     if(!!onSuccess){
      setBaseList(onSuccess?.data?.data);
      setTotalPage(onSuccess?.data?.last_page);
      setCurrentPage(onSuccess?.data?.current_page);
     }
      },
      onFail => {
      console.log('Fail:', onFail);
      showToast(onFail)
      },
      onError => {
      console.error('Error:', onError);
      showToast("Somthing Wents to worng")
    
      })
  }

  const GetCat = () => {
    GET_WITH_TOKEN("/api/categories", "", e => {
      // setBaseList(e.data);
      console.log(e, "eeeeee")
      // showToast("success")
      setcategoriesData(e.data)
    }, f => {
      showToast(f.message || f.msg)
    }, n => {
      showToast(n.message || n.msg || "Somthing Wants to rong")
    })
  }

  const CreatePost = () => {


    const contentState = Data?.upr_sec_desc.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlContent = draftToHtml(rawContentState);

    const contentState1 = Data?.low_sec_desc.getCurrentContent();
    const rawContentState1 = convertToRaw(contentState1);
    const htmlContent1 = draftToHtml(rawContentState1);


    const contentState2 = Data?.cta_description.getCurrentContent();
    const rawContentState2 = convertToRaw(contentState2);
    const htmlContent2 = draftToHtml(rawContentState2);


    var formdata = new FormData();
    formdata.append("name", Data?.name);
    formdata.append("slug", Data?.slug);
    formdata.append("upr_sec_title", Data?.upr_sec_title);
    formdata.append("upr_sec_desc", htmlContent);
    formdata.append("most_popular_category_id", Data?.most_popular_category_id);
    formdata.append("middle_section_category_id", Data?.middle_section_category_id);
    formdata.append("lower_section_category_id", Data?.lower_section_category_id);
    formdata.append("additional_lower_section_category_id", Data?.additional_lower_section_category_id);
    formdata.append("low_sec_title", Data?.low_sec_title);
    formdata.append("low_sec_desc", htmlContent1);
    formdata.append("cta_title", Data?.cta_title);
    formdata.append("publish", Data?.publish);
    formdata.append("status", Data?.status);
    formdata.append("cta_description", htmlContent2);
    questions?.map((res,index)=>{
      formdata.append(`question_keys[${index}][key_name]`,res?.name);
      formdata.append(`question_keys[${index}][key_value]`, res?.value);
    })
    if (BaseCatImg) {
      formdata.append("base_top_image", BaseCatImg)
    }
    if (BaseCatImgUrl) {
      formdata.append("base_top_image_exist", BaseCatImgUrl)
    }
    ImagesArray?.map((res) => {
      formdata.append("base-image[]", res);
    })
    ImagesArray1?.map((res) => {
      formdata.append("base-image_exist[]", res);
    })


    if (Edit) {
      formdata.append('_method', "PUT")
    }




    Post_WITH_TOKEN(Edit ? `/api/base-categories/${id}` : "/api/base-categories", formdata, e => {
      console.log("eeee", e)
      showToast(e?.message || e?.msg || "Success")
      GetbaseCat(CurrentPage)
      clearState()
    },
      F => {
        showToast(F?.message || F?.msg || "Fail")
      },
      n => {
        showToast(n?.message || n?.msg || "Network error")
      }
    )

  }

  const [ImagesArray, setImagesArray] = useState([]);
  const [ImagesArray1, setImagesArray1] = useState([]);
  const [images, setImages] = useState('');



  const AddPostImages = (e) => {

    console.log(e, "target");
    let files = e.target.files;
    const New = [...ImagesArray];
    const Array = [...Data.base_image]

    // Iterate over each file in the FileList
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      New.push(file); // Push each file to ImagesArray1

      const imageUrl = URL.createObjectURL(file); // Create URL for preview
      Array.push({ image: imageUrl }); // Push image URL to Images
    }

    setImagesArray(New);
    setData({ ...Data, base_image: Array });


  }


  const DeletePostImage = (index) => {
    const ArrayNew = [...ImagesArray]
    ArrayNew.splice(index, 1)
    setImagesArray(ArrayNew)
    const Array = [...Data.base_image]
    Array.splice(index, 1)
    setData({ ...Data, base_image: Array });
  }
  const DeletePostImage1 = (index) => {
    const ArrayNew = [...ImagesArray1]
    ArrayNew.splice(index, 1)
    setImagesArray1(ArrayNew)
  }

  const onChange = (e, name) => {
    setData({ ...Data, [name]: e })
  }


  const ShowById = (id) => {
    setId(id)
    GET_WITH_TOKEN_PARAMS("/api/base-categories", id,
      onSuccess => {
        console.log('Success:', onSuccess);
        if (!!onSuccess) {
          showToast(onSuccess?.message || onSuccess?.msg || "Success");
          console.log(onSuccess.message);
          // setData(pre=>({...pre,...onSuccess}))

          if (onSuccess?.data?.hasOwnProperty("name")) {
            setData(prevState => ({ ...prevState, name: onSuccess?.data?.name }));
          }
          if (onSuccess?.data?.hasOwnProperty("slug")) {
            setData(prevState => ({ ...prevState, slug: onSuccess?.data?.slug }));
          }
          // Update other properties similarly...
          if (onSuccess?.data?.hasOwnProperty("upr_sec_title")) {
            setData(prevState => ({ ...prevState, upr_sec_title: onSuccess?.data?.upr_sec_title }));
          }

          if (onSuccess?.data?.hasOwnProperty("most_popular_category_id")) {
            setData(prevState => ({ ...prevState, most_popular_category_id: onSuccess?.data?.most_popular_category_id }));
          }
          if (onSuccess?.data?.hasOwnProperty("middle_section_category_id")) {
            setData(prevState => ({ ...prevState, middle_section_category_id: onSuccess?.data?.middle_section_category_id }));
          }
          if (onSuccess?.data?.hasOwnProperty("lower_section_category_id")) {
            setData(prevState => ({ ...prevState, lower_section_category_id: onSuccess?.data?.lower_section_category_id }));
          }
          if (onSuccess?.data?.hasOwnProperty("additional_lower_section_category_id")) {
            setData(prevState => ({ ...prevState, additional_lower_section_category_id: onSuccess?.data?.additional_lower_section_category_id }));
          }
          if (onSuccess?.data?.hasOwnProperty("low_sec_title")) {
            setData(prevState => ({ ...prevState, low_sec_title: onSuccess?.data?.low_sec_title }));
          }
          if (onSuccess?.data?.hasOwnProperty("cta_title")) {
            setData(prevState => ({ ...prevState, cta_title: onSuccess?.data?.cta_title }));
          }

          if (onSuccess?.data?.hasOwnProperty("publish")) {
            setData(prevState => ({ ...prevState, publish: onSuccess?.data?.publish }));
          }

          if (onSuccess?.data?.hasOwnProperty("status")) {
            setData(prevState => ({ ...prevState, status: onSuccess?.data?.status }));
          }

          const blocksFromHTML = convertFromHTML(onSuccess?.data?.cta_description);
          const contentState = ContentState.createFromBlockArray(blocksFromHTML);
          let data = EditorState.createWithContent(contentState);
          const blocksFromHTML1 = convertFromHTML(onSuccess?.data?.upr_sec_desc);
          const contentState1 = ContentState.createFromBlockArray(blocksFromHTML1);
          let data1 = EditorState.createWithContent(contentState1);
          const blocksFromHTML2 = convertFromHTML(onSuccess?.data?.low_sec_desc);
          const contentState2 = ContentState.createFromBlockArray(blocksFromHTML2);
          let data2 = EditorState.createWithContent(contentState2);


          if (onSuccess?.data?.hasOwnProperty("cta_description")) {
            setData(prevState => ({ ...prevState, cta_description: data }));
          }
          if (onSuccess?.data?.hasOwnProperty("upr_sec_desc")) {
            setData(prevState => ({ ...prevState, upr_sec_desc: data1 }));
          }
          if (onSuccess?.data?.hasOwnProperty("low_sec_desc")) {
            setData(prevState => ({ ...prevState, low_sec_desc: data2 }));
          }
          let imges = [];
          let imges1 = [];

          onSuccess?.data.images?.forEach((res) => {
            if (res.image_type === "header") {
              imges.push(res.img_path);
            } else if (res.image_type === "top") {
              imges1.push(res);
            }
          });
          setBaseCatImgUrl(imges1[0]?.img_path)
          setImagesArray1(imges);
          setQuestions(onSuccess?.data?.basecategoryquestion);
        }
      },
      onFail => {
        console.log('Fail:', onFail);
        showToast(onFail)
      },
      onError => {
        console.error('Error:', onError);
        showToast("Somthing Wents to worng")

      })
  }

  const clearState = () => {
    setData({
      name: "",
      slug: "",
      upr_sec_title: "",
      upr_sec_desc: "",
      most_popular_category_id: "",
      middle_section_category_id: '',
      lower_section_category_id: '',
      additional_lower_section_category_id: "",
      low_sec_title: "",
      low_sec_desc: "",
      base_image: [],
      cta_description: "",
      cta_title: "",
      publish: 1,
      status: 1,
    });
    setBaseCatImgUrl('');
    setBaseCatImg('');
    setImagesArray([]);
    setImagesArray1([]);
  };




  const PublishData = [
    { id: 1, name: "Yes", value: 1 }, { id: 0, name: "No", value: 0 }
  ]

  const StatusData = [
    { id: 1, name: "Active", value: 1 }, { id: 0, name: "InActive", value: 0 }
  ]


  return (
    <div>
      <div className='container mt-1'>
        <div className='row'>
          <div className='col-lg-12  '>

            <div className='card'>
              <div className='card-header'>
                <p style={{ fontSize: '25px' }}>Base Categories Product Filter</p>



                <div class="mb-3  mt-4 d-flex">
                  <input type="text" class="form-control " id="exampleFormControlInput1"
                    placeholder="Enter Name" style={{ width: '20%' }} onChange={(e)=>{setsearch(e.target.value)}}/>

                  <button onClick={()=>{GetbaseCat()}} type='btn' className='ms-4' style={{ backgroundColor: '#008c93', color: '#fff', border: 'none', padding: '7px 15px', borderRadius: '8px' }}>Search</button>

                  {/* <button type='btn' className='ms-2' style={{backgroundColor:'transparent', color:'#000', padding:'7px 15px', borderRadius:'8px', border:'1px solid gray'}}>Reset</button> */}


                  <button type='btn' className='ms-2' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{ backgroundColor: '#366fe9', color: '#000', padding: '7px 15px', borderRadius: '8px', border: '1px solid gray' }}>Add New</button>
                </div>
                <div>

                </div>
              </div>
              <div className='card-body' style={{ height: 550, overflowY: "scroll" }}>
                <p style={{ fontSize: '20px' }}>View All Products</p>

                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">id</th>
                      <th scope="col">image</th>
                      <th scope="col">Name</th>
                      <th scope="col">slug</th>
                      <th scope="col">description</th>
                      <th scope="col">post</th>
                      <th scope="col">Parent</th>
                      {/* <th scope="col">Country</th>
      <th scope="col">Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      BaseList?.length > 0 ?
                        BaseList?.map((res, index) => {
                          return (
                            <>
                              <tr>
                                <th scope="row">{res.id}</th>
                                <td><div><img src={hello} alt='img' style={{ width: '60px' }} /></div></td>
                                <td>{res?.name || "Not Found"}</td>
                                <td>{res?.slug}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  <div >
                                    {/* <button className='py-2' style={{backgroundColor:'#3dc934', color:'#fff',
           border:'none', borderRadius:'4px'}}>Show</button> */}
                                    <button onClick={() => { setEdit(true); ShowById(res.id) }} className='py-2 mx-2' style={{
                                      backgroundColor: '#3cc3b0', color: '#fff',
                                      border: 'none', borderRadius: '4px'
                                    }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" >Edit</button>
                                    <button className='py-2' style={{
                                      backgroundColor: '#9ba322', color: '#fff',
                                      border: 'none', borderRadius: '4px'
                                    }}>Delete</button>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )
                        }) : null
                    }



                  </tbody>

                </table>
                {
                  BaseList?.length > 0 ? null :
                    <div className="data-not-found">
                      <h2 className="bounce">Data Not Found</h2>
                      <p className="fade-in">Sorry, the requested data could not be found.</p>
                    </div>
                }
              </div>
              <div style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
          <BasicPagination count={TotalPage}  onChange={(e)=>{GetbaseCat(e)}}/>
          </div>
            </div>




          </div>
        </div>
      </div>






      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: '85%' }}>
        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel"></h5>
          <button type="button" onClick={() => { clearState() }} class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div class="offcanvas-body">

          <CommanInput multiple={true} placeholder={"Header images"} fileInput={true} input={false} textArea={false} type='text' describtionShow={true} desTitle={"Header images"} onChange={(e) => { AddPostImages(e) }} />

          <div className='w-100 row'>
            {
              ImagesArray1?.map((res, index) => {
                return (
                  <>
                    <div className='col-2'>
                      <img className='w-100' src={res} alt='#' style={{ justifyContent: "center", alignItems: "center", width: 100 }} />
                      <button className='w-100' onClick={() => { DeletePostImage1(index) }}>
                        Delete
                      </button>
                    </div>
                  </>
                )
              })
            }
          </div>
          <div className='w-100 row'>
            {
              Data?.base_image?.map((res, index) => {
                return (
                  <>
                    <div className='col-2'>
                      <img className='w-100' src={res.image} alt='#' style={{ justifyContent: "center", alignItems: "center", width: 100 }} />
                      <button className='w-100' onClick={() => { DeletePostImage(index) }}>
                        Delete
                      </button>
                    </div>
                  </>
                )
              })
            }
          </div>

          <CommanInput placeholder={"Base category image"} fileInput={true} input={false} textArea={false} type='text' describtionShow={true} desTitle={"Base category image"} onChange={(e) => {
            setBaseCatImgUrl(URL.createObjectURL(e.target.files[0]));
            setBaseCatImg(e.target.files[0])
          }} />

          <div className='col-2'>
            {
              BaseCatImgUrl &&
              <img className='w-100' src={BaseCatImgUrl} alt='#' style={{ justifyContent: "center", alignItems: "center", width: 100 }} />

            }
          </div>

          <CommanInput value={Data?.name} placeholder={"Basic category Title"} type='text' describtionShow={true} desTitle={"Enter Basic category Title"} onChange={(e) => { onChange(e, "name") }} />
          <CommanInput value={Data?.slug} placeholder={"Enter slug"} type='text' describtionShow={true} desTitle={" Slug"} onChange={(e) => { console.log(e, "data"); onChange(e, "slug") }} />
          <CommanInput value={Data?.upr_sec_title} placeholder={"Introduction Title"} type='text' describtionShow={true} desTitle={"Introduction Title"} onChange={(e) => { console.log(e, "data"); onChange(e, "upr_sec_title") }} />


          <div class="mb-2">
            <label for="exampleFormControlTextarea1" class="form-label" style={{ fontWeight: '600' }}>Introduction Content</label>
            <div className='' style={{ border: "1px solid gray" }}>
              <Editor
                editorState={Data?.upr_sec_desc}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => { console.log(e, "data"); setData({ ...Data, upr_sec_desc: e }) }}
              /></div>
          </div>





          {/* <CommanInput value={Data?.upr_sec_desc} title={"upper section description"} placeholder={"upper section description"} type='text' describtionShow={true} input={false} textArea={true} desTitle={"upper section description"} onChange={(e)=>{console.log(e,"data");onChange(e,"upr_sec_desc")}} style={{height:80}}/> */}
          <CommanDropDown value={Data?.most_popular_category_id} title={"Pls Select"} desTitle={"Most Popular cateogry"} onChange={(e) => { onChange(e, "most_popular_category_id") }} Data={categoriesData || []} />
          <CommanDropDown value={Data?.middle_section_category_id} title={"Pls Select"} desTitle={"Middle Section cateogry"} onChange={(e) => { onChange(e, "middle_section_category_id") }} Data={categoriesData || []} />
          <CommanDropDown value={Data?.lower_section_category_id} title={"Pls Select"} desTitle={"Middle 2 cateogry"} onChange={(e) => { onChange(e, "lower_section_category_id") }} Data={categoriesData || []} />
          <CommanDropDown value={Data?.additional_lower_section_category_id} title={"Pls Select"} desTitle={"Middle 3 cateogry"} Data={categoriesData || []} onChange={(e) => { onChange(e, "additional_lower_section_category_id") }} />
          <CommanInput value={Data?.low_sec_title} placeholder={"Middle 4 Title"} type='text' describtionShow={true} desTitle={"Middle 4 Title"} onChange={(e) => { console.log(e, "data"); onChange(e, "low_sec_title") }} />

          <div class="mb-2">
            <label for="exampleFormControlTextarea1" class="form-label" style={{ fontWeight: '600' }}> Middle 4 Description</label>
            <div className='' style={{ border: "1px solid gray" }}>
              <Editor
                editorState={Data?.low_sec_desc}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => { console.log(e, "data"); setData({ ...Data, low_sec_desc: e }) }}
              /></div>
          </div>

          {/* <CommanInput value={Data?.low_sec_desc} title={"low_sec_desc"} placeholder={"Low section description"} type='text' describtionShow={true} input={false} textArea={true} desTitle={"Low section description"} onChange={(e)=>{console.log(e,"data");onChange(e,"low_sec_desc")}} style={{height:80}}/> */}
          <CommanInput value={Data?.cta_title} placeholder={"CTA Title"} type='text' describtionShow={true} desTitle={"CTA Title"} onChange={(e) => { console.log(e, "data"); onChange(e, "cta_title") }} />


          {/* <CommanInput value={Data?.cta_description} title={"CTA Description"} placeholder={"CTA description"} type='text' describtionShow={true} input={false} textArea={true} desTitle={"CTA description"} onChange={(e)=>{console.log(e,"data");onChange(e,"cta_description")}} style={{height:80}}/> */}


          <div class="mb-2">
            <label for="exampleFormControlTextarea1" class="form-label" style={{ fontWeight: '600' }}>Enter CTA Description</label>
            <div className='' style={{ border: "1px solid gray" }}>
              <Editor
                editorState={Data?.cta_description}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => { console.log(e, "data"); setData({ ...Data, cta_description: e }) }}
              /></div>
          </div>

          <div onClick={()=>{AddNewQuestions()}} className='w-100 mt-2 mb-2 d-flex' style={{justifyContent:"flex-start",alignItems:"flex-start",cursor:"pointer"}}>
    <div className='d-flex m-2' style={{justifyContent:"center",alignItems:"center"}}>
    <img src={Imges?.add} alt='###' style={
        {height:30}
    }/>
    <h6 className='' style={{marginLeft:20,color:"red"}}>Add Faq Asked Questions</h6>
    </div>
  </div>
  <div className='d-flex w-100 row'>
    {
    questions?.map((res,index)=>{
      
        return(
            <>
            <div className='col-5 p-2'>
            <CommanInput textArea={true} input={false} placeholder={"Enter Questions"} type='text' describtionShow={true} desTitle={" Question"}  onChange={(e) => {
                            const newArray = [...questions];
                            newArray[index].name = e;
                            setQuestions(newArray);
                        }}
                        value={res.name}
          />            
            </div>
            <div className='col-5 p-2'>
            <CommanInput textArea={true} input={false} placeholder={"Enter Answare"} type='text' describtionShow={true} desTitle={"Answare"}  onChange={(e) => {
                            const newArray = [...questions];
                            newArray[index].value = e;
                            setQuestions(newArray);
                        }} value={res.value}/>            
            </div>
            <div onClick={()=>{DeleteQuestion(index)}} className='col-2' style={{marginTop:35,alignItems:"center",justifyContent:"center"}}>
               <button>Delete</button>
            </div>
            </>
        )
    })
    }
  </div>
          <CommanDropDown displayValue="publish" value={Data?.publish} title={"Pls Select"} desTitle={"publish"} Data={PublishData} onChange={(e, name) => { console.log(e, name); setData({ ...Data, publish: e }) }} />

          <CommanDropDown displayValue="Status" value={Data?.status} title={"Pls Select"} desTitle={"Status"} Data={StatusData} onChange={(e, name) => { console.log(e, name); setData({ ...Data, status: e }) }} />

          <Button data-bs-dismiss="offcanvas" aria-label="Close" className='mt-3' onClick={() => { handleSubmit() }}>Submit</Button>


        </div>
      </div>
    </div>
  )
}

export default Subcat