import React from 'react';
import { Container } from 'react-bootstrap';

const CommanInput = ({ContainerStyle,placeholder,onChange=()=>{},style,desTitle,describtionTitle,describtionStyle,describtionShow=true,type="text",textArea=false,input=true,fileInput=false,value,multiple=false}) => {
  return (
    <>
    <div className='mb-2 mt-3' style={ContainerStyle}>
    {
        describtionShow&& <label for="exampleFormControlInput1" class="form-label" style={{fontWeight:'600'}}>{desTitle}</label>
    }
    {
    input && <input type={type} class="form-control" id="exampleFormControlInput1" placeholder={placeholder} onChange={(e)=>onChange(e.target.value)} style={style} value={value}/>
    }
    
    {
    textArea &&  <textArea class="form-control" value={value}  id="exampleFormControlInput1" placeholder={placeholder} onChange={(e)=>{onChange(e.target.value)}} style={style} >{value}</textArea>     
    } 
    {
      fileInput && <input multiple={multiple} type={"file"} class="form-control" id="exampleFormControlInput1" placeholder={placeholder} onChange={(e)=>onChange(e)} style={style}/>
    }
    </div>
    </>
  )
}

export default CommanInput;