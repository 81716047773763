import React, { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import { MyContext } from '../Context/MyContext';

const SideBar = () => {
    const Path = "/Home";
    const location = useLocation();
    console.log(location.pathname);

   const {logout} = useContext(MyContext);


  return (
    <div className='col-12 w-100 h-100' style={{borderRight:"1px solid gray",background:"#000"}}>
         <div className="p-2">
          <img alt="Logo" src={""} width="90%" />
        </div>
        <div
          style={{
            boxSizing: "border-box",
            overflowY: "scroll",
            width: "95%",
            height: "90vh",
            overflowX: "hidden",
          }}
        >
          <div
            className="row"
            style={{
              justifyContent: "center",
              marginLeft: "10px",
              marginTop: "30px",
              backgroundColor: location.pathname === "/Home" ? "#303030" : "",
            }}
          >
            <NavLink
              className="p-2"
              to="/Home"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="row">
                <div className="col-2">
                  <svg
                    width="20"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.52 0.191992C12.3751 0.0680481 12.1907 -6.10352e-05 12 -6.10352e-05C11.8093 -6.10352e-05 11.6249 0.0680481 11.48 0.191992L0 10.032V21.6C0 22.2365 0.252856 22.847 0.702944 23.297C1.15303 23.7471 1.76348 24 2.4 24H8.8C9.01217 24 9.21566 23.9157 9.36569 23.7657C9.51571 23.6157 9.6 23.4122 9.6 23.2V18.4C9.6 17.7635 9.85286 17.153 10.3029 16.7029C10.753 16.2528 11.3635 16 12 16C12.6365 16 13.247 16.2528 13.6971 16.7029C14.1471 17.153 14.4 17.7635 14.4 18.4V23.2C14.4 23.4122 14.4843 23.6157 14.6343 23.7657C14.7843 23.9157 14.9878 24 15.2 24H21.6C22.2365 24 22.847 23.7471 23.2971 23.297C23.7471 22.847 24 22.2365 24 21.6V10.032L12.52 0.191992Z"
                      fill={location.pathname === "/Home" ? "#F44CC5" : "#8A8A8A"}
                    />
                  </svg>
                </div>
                <div className="col-10 " style={{ marginLeft: "-10px" }}>
                  <span className="ms-2">Home</span>
                </div>
              </div>
            </NavLink>
         </div>
          <div
            className="row"
            style={{
              justifyContent: "center",
              marginLeft: "10px",
              marginTop: "30px",
              backgroundColor: location.pathname === "/Cat" ? "#303030" : "",
            }}
          >
            <NavLink
              className="p-2"
              to="/Cat"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="row">
                <div className="col-2">
                  <svg
                    width="22"
                    height="26"
                    viewBox="0 0 22 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.375 0.34375H4.4375C3.31862 0.34375 2.24556 0.788224 1.45439 1.57939C0.663224 2.37056 0.21875 3.44362 0.21875 4.5625V24.25C0.21875 24.623 0.366908 24.9806 0.630631 25.2444C0.894354 25.5081 1.25204 25.6562 1.625 25.6562H18.5C18.873 25.6562 19.2306 25.5081 19.4944 25.2444C19.7581 24.9806 19.9062 24.623 19.9062 24.25C19.9062 23.877 19.7581 23.5194 19.4944 23.2556C19.2306 22.9919 18.873 22.8438 18.5 22.8438H3.03125V22.375C3.03125 22.002 3.17941 21.6444 3.44313 21.3806C3.70685 21.1169 4.06454 20.9688 4.4375 20.9688H20.375C20.748 20.9688 21.1056 20.8206 21.3694 20.5569C21.6331 20.2931 21.7812 19.9355 21.7812 19.5625V1.75C21.7812 1.37704 21.6331 1.01935 21.3694 0.755631C21.1056 0.491908 20.748 0.34375 20.375 0.34375ZM18.9688 18.1562H4.4375C3.95784 18.154 3.48163 18.2373 3.03125 18.4023V4.5625C3.03125 4.18954 3.17941 3.83185 3.44313 3.56813C3.70685 3.30441 4.06454 3.15625 4.4375 3.15625H18.9688V18.1562Z"
                      fill={
                        location.pathname === "/Cat"
                          ? "url(#paint0_linear_6_1163)"
                          : "#8A8A8A"
                      }
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_6_1163"
                        x1="11"
                        y1="0.34375"
                        x2="11"
                        y2="25.6562"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#17CBCB" />
                        <stop offset="1" stop-color="#17CBCB" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="col-10 " style={{ marginLeft: "-10px" }}>
                  <span className="ms-2"> Categories</span>
                </div>
              </div>
            </NavLink>
          </div>

       

          <div
            className="row"
            style={{
              justifyContent: "center",
              marginLeft: "10px",
              marginTop: "30px",
              backgroundColor: location.pathname === "/SubCat" ? "#303030" : "",
            }}
          >
            <NavLink
              className="p-2"
              to="/SubCat"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="row">
                <div className="col-2">
                  <svg
                    width="22"
                    height="26"
                    viewBox="0 0 22 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.375 0.34375H4.4375C3.31862 0.34375 2.24556 0.788224 1.45439 1.57939C0.663224 2.37056 0.21875 3.44362 0.21875 4.5625V24.25C0.21875 24.623 0.366908 24.9806 0.630631 25.2444C0.894354 25.5081 1.25204 25.6562 1.625 25.6562H18.5C18.873 25.6562 19.2306 25.5081 19.4944 25.2444C19.7581 24.9806 19.9062 24.623 19.9062 24.25C19.9062 23.877 19.7581 23.5194 19.4944 23.2556C19.2306 22.9919 18.873 22.8438 18.5 22.8438H3.03125V22.375C3.03125 22.002 3.17941 21.6444 3.44313 21.3806C3.70685 21.1169 4.06454 20.9688 4.4375 20.9688H20.375C20.748 20.9688 21.1056 20.8206 21.3694 20.5569C21.6331 20.2931 21.7812 19.9355 21.7812 19.5625V1.75C21.7812 1.37704 21.6331 1.01935 21.3694 0.755631C21.1056 0.491908 20.748 0.34375 20.375 0.34375ZM18.9688 18.1562H4.4375C3.95784 18.154 3.48163 18.2373 3.03125 18.4023V4.5625C3.03125 4.18954 3.17941 3.83185 3.44313 3.56813C3.70685 3.30441 4.06454 3.15625 4.4375 3.15625H18.9688V18.1562Z"
                      fill={
                        location.pathname === "/SubCat"
                          ? "url(#paint0_linear_6_1163)"
                          : "#8A8A8A"
                      }
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_6_1163"
                        x1="11"
                        y1="0.34375"
                        x2="11"
                        y2="25.6562"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#17CBCB" />
                        <stop offset="1" stop-color="#17CBCB" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="col-10 " style={{ marginLeft: "-10px" }}>
                  <span className="ms-2">Base Categories</span>
                </div>
              </div>
            </NavLink>
          </div>


          <div
            className="row"
            style={{
              justifyContent: "center",
              marginLeft: "10px",
              marginTop: "30px",
              backgroundColor: location.pathname === "/Post" ? "#303030" : "",
            }}
          >
            <NavLink
              className="p-2"
              to="/Post"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="row">
                <div className="col-2">
                  <svg
                    width="22"
                    height="26"
                    viewBox="0 0 22 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.375 0.34375H4.4375C3.31862 0.34375 2.24556 0.788224 1.45439 1.57939C0.663224 2.37056 0.21875 3.44362 0.21875 4.5625V24.25C0.21875 24.623 0.366908 24.9806 0.630631 25.2444C0.894354 25.5081 1.25204 25.6562 1.625 25.6562H18.5C18.873 25.6562 19.2306 25.5081 19.4944 25.2444C19.7581 24.9806 19.9062 24.623 19.9062 24.25C19.9062 23.877 19.7581 23.5194 19.4944 23.2556C19.2306 22.9919 18.873 22.8438 18.5 22.8438H3.03125V22.375C3.03125 22.002 3.17941 21.6444 3.44313 21.3806C3.70685 21.1169 4.06454 20.9688 4.4375 20.9688H20.375C20.748 20.9688 21.1056 20.8206 21.3694 20.5569C21.6331 20.2931 21.7812 19.9355 21.7812 19.5625V1.75C21.7812 1.37704 21.6331 1.01935 21.3694 0.755631C21.1056 0.491908 20.748 0.34375 20.375 0.34375ZM18.9688 18.1562H4.4375C3.95784 18.154 3.48163 18.2373 3.03125 18.4023V4.5625C3.03125 4.18954 3.17941 3.83185 3.44313 3.56813C3.70685 3.30441 4.06454 3.15625 4.4375 3.15625H18.9688V18.1562Z"
                      fill={
                        location.pathname === "/Post"
                          ? "url(#paint0_linear_6_1163)"
                          : "#8A8A8A"
                      }
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_6_1163"
                        x1="11"
                        y1="0.34375"
                        x2="11"
                        y2="25.6562"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#17CBCB" />
                        <stop offset="1" stop-color="#17CBCB" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="col-10 " style={{ marginLeft: "-10px" }}>
                  <span className="ms-2"> Post</span>
                </div>
              </div>
            </NavLink>
          </div>
         


         <div
          onClick={()=>{
            logout() 
        }}
            className="row"
            style={{
              justifyContent: "center",
              marginLeft: "10px",
              marginTop: "30px",
              backgroundColor: "",
              cursor:"pointer"
            }}
          >
            <div
              className="p-2"
              
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="row">
                <div className="col-2">
                  <svg
                    width="22"
                    height="26"
                    viewBox="0 0 22 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.375 0.34375H4.4375C3.31862 0.34375 2.24556 0.788224 1.45439 1.57939C0.663224 2.37056 0.21875 3.44362 0.21875 4.5625V24.25C0.21875 24.623 0.366908 24.9806 0.630631 25.2444C0.894354 25.5081 1.25204 25.6562 1.625 25.6562H18.5C18.873 25.6562 19.2306 25.5081 19.4944 25.2444C19.7581 24.9806 19.9062 24.623 19.9062 24.25C19.9062 23.877 19.7581 23.5194 19.4944 23.2556C19.2306 22.9919 18.873 22.8438 18.5 22.8438H3.03125V22.375C3.03125 22.002 3.17941 21.6444 3.44313 21.3806C3.70685 21.1169 4.06454 20.9688 4.4375 20.9688H20.375C20.748 20.9688 21.1056 20.8206 21.3694 20.5569C21.6331 20.2931 21.7812 19.9355 21.7812 19.5625V1.75C21.7812 1.37704 21.6331 1.01935 21.3694 0.755631C21.1056 0.491908 20.748 0.34375 20.375 0.34375ZM18.9688 18.1562H4.4375C3.95784 18.154 3.48163 18.2373 3.03125 18.4023V4.5625C3.03125 4.18954 3.17941 3.83185 3.44313 3.56813C3.70685 3.30441 4.06454 3.15625 4.4375 3.15625H18.9688V18.1562Z"
                      fill={
                         "#8A8A8A"
                      }
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_6_1163"
                        x1="11"
                        y1="0.34375"
                        x2="11"
                        y2="25.6562"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#17CBCB" />
                        <stop offset="1" stop-color="#17CBCB" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="col-10 " style={{ marginLeft: "-10px" }}>
                  <span className="ms-2">Log out</span>
                </div>
              </div>
            </div>
          </div>

         
        </div>
    </div>
  )
}

export default SideBar;