import Multiselect from 'multiselect-react-dropdown';
import React from 'react';

const CommanDropDown = ({ ContainerStyle, title, onChange = () => {}, style, desTitle, describtionTitle, describtionStyle, describtionShow = true, Data, useMultiselect = false,slectedData,value,displayValue="post_slug",onRemove}) => {
    const handleDropdownChange = (selectedListOrEvent, selectedItem) => {
        console.log(selectedListOrEvent,"selectedListOrEvent")
        if (useMultiselect) {
            if (selectedListOrEvent && selectedListOrEvent.length > 0) {
                selectedListOrEvent?.map((res)=>{
                    onChange(res.id, res?.post_slug || res?.slug || res?.name || res?.post_name,res);
                })
            } else {
                // Handle the case when no item is selected
                onChange("", "");
            }
        } else {
            const e = selectedListOrEvent;
            const selectedValue = e.target.value;
            const selectedItem = Data.find((item) => item.id == selectedValue);
            onChange(selectedValue, selectedItem?.post_slug || selectedItem?.slug || selectedItem?.name,);
        }
    };

   console.log(Data,"data=====>")

    return (
        <div className='mb-2' style={ContainerStyle}>
            {describtionShow && <label htmlFor="exampleFormControlInput1" className="form-label" style={{ fontWeight: '600' }}>{desTitle}</label>}

            {useMultiselect ? (
                <Multiselect
                    options={Data}
                    displayValue={displayValue}
                    onSelect={handleDropdownChange} // Pass your custom onChange handler here
                    selectedValues={slectedData}
                    onRemove={onRemove}
                />
            ) : (
                <select className="form-select my-1" aria-label="Default select example" onChange={handleDropdownChange}>
                    <option  value={""}>{title}</option>
                    {Data?.map((res, ind) => (
                        <option selected={value==res.id?true:false} value={res.id} key={res.post_slug || res.slug || res.name || res.post_slug}>
                            {res.post_slug || res.slug || res.name}
                        </option>
                    ))}
                </select>
            )}
        </div>
    );
}

export default CommanDropDown;
