import React, { useContext } from 'react'
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import SideBar from './Components/SideBar';
import Cat from './Components/Cat';
import Subcat from './Components/Subcat';
import { MyContext } from './Context/MyContext';
import Login from './Components/Login';
import Post from './Components/Post';
import "./App.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const App = () => {
  const { isLoggedIn } = useContext(MyContext);
  return (
    <>

   <ToastContainer/>
    <div className='row'>
      {
        isLoggedIn==true?<div className='col-2'>
        <SideBar/>
      </div>:null
      }

      <div className='col-10'>
      <Routes>
      <Route exact path='/Login' Component={Login}/>
      <Route exact path='/Home' Component={Home}/>
      <Route exact path='/Cat' Component={Cat}/>
      <Route exact path='/SubCat' Component={Subcat}/>
      <Route exact path='/Post' Component={Post}/>
    </Routes>
     
      
</div>
</div>
  
    </>
  )
}

export default App;