import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const MyContext = createContext('');

const MyContextProvider = ({ children }) => {
  const Navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      // Navigate('/Home');
    } else {
      setIsLoggedIn(false);
      Navigate('/login');
    }
  }, [Navigate]);

  const login = (token) => {
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
    Navigate('/Home');
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    Navigate('/login');
  };

  return (
    <MyContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </MyContext.Provider>
  );
};

export { MyContext, MyContextProvider };
