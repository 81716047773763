// toastService.js

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const showToast = (message) => {
  toast.success(message);
};

export { showToast };
