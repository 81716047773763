import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function BasicPagination({count,onChange=()=>{},resetpage}) {
    const [page, setPage] = React.useState(resetpage||1);
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
      onChange(value)
    };
  
  return (
    <Stack spacing={2}>
      <Pagination count={count} color="primary" page={page} onChange={handleChange} />
    </Stack>
  );
}